
// To change portfolio colors globally go to the  _globalColor.scss file

import emoji from "react-easy-emoji";
import splashAnimation from "./assets/lottie/splashAnimation"; // Rename to your file name for custom animation

// Splash Screen

const splashScreen = {
  enabled: true, // set false to disable splash screen
  animation: splashAnimation,
  duration: 2000 // Set animation duration as per your animation
};

// Summary And Greeting Section

const illustration = {
  animated: true // Set to false to use static SVG
};

const greeting = {
  username: "Kevin Tsafack",
  title: "Hi, I'm Kevin",
  subTitle: (
    <>
      {emoji(
        "This webpage was built using React and containerized with Docker. The code is deployed through a CI/CD workflow from Github Action to automate the build, testing, and deployment. Nginx, as a reverse proxy, serves the app "
      )}
      <a href="https://gist.github.com/8Ten10/36e751597c7b7fc23865c20732e6c361" target="_blank" rel="noopener noreferrer">
        Click Here to Check out the source code and workflow.
      </a>
    </>
  ),
  resumeLink:
    "https://docs.google.com/documen17EIUjm4nbNtIK9BrzoRuA_6SOzQofEuP5BQtIGLM2Onh_4W8-1tDr2HaklrxFQiOt9c/pub", // Set to empty to hide the button
  displayGreeting: true // Set false to hide this section, defaults to true
};

// Social Media Links

const socialMediaLinks = {
  github: "https://github.com/8ten10",
  linkedin: "https://www.linkedin.com/in/8ten10/",
  gmail: "bonjour@kevintsafack.io",
  youtube: "https://youtube.com/@8ten10",
  // facebook: "https://www.facebook.com/",
 // medium: "https://medium.com/",
  // stackoverflow: "https://stackoverflow.com",
  // Instagram, Twitter and Kaggle are also supported in the links!
  // To customize icons and social links, tweak src/components/SocialMedia
  display: true // Set true to display this section, defaults to false
};

// Skills Section

const skillsSection = {
  title: "What I do",
  subTitle: "CRAZY ",
  skills: [
    emoji(
      "⚡ Develop highly interactive "
    ),
    emoji("⚡ Progressive "),
    emoji(
      "⚡ Integration of "
    )
  ],

  /* Make Sure to include correct Font Awesome Classname to view your icon
https://fontawesome.com/icons?d=gallery */

  softwareSkills: [
    {
      skillName: "linux",
      fontAwesomeClassname: "fab fa-html5"
    },
    {
      skillName: "reactjs",
      fontAwesomeClassname: "fab fa-react"
    },
    {
      skillName: "nodejs",
      fontAwesomeClassname: "fab fa-node"
    },
    {
      skillName: "sql-database",
      fontAwesomeClassname: "fas fa-database"
    },
    {
      skillName: "windows",
      fontAwesomeClassname: "fab fa-aws"
    },
    {
      skillName: "python",
      fontAwesomeClassname: "fab fa-python"
    },
    {
      skillName: "docker",
      fontAwesomeClassname: "fab fa-docker"
    }
  ],
  display: false // Set false to hide this section, defaults to true
};

// Education Section

const educationInfo = {
  display: false, // Set false to hide this section, defaults to true
  schools: [
    {
      schoolName: "Montgomery College",
      logo: require("./assets/images/college1.jpg"),
      subHeader: "Associate of Applied Science in Cybersecurity",
      duration: " June 1995 - May 1998",
      desc: "Designated as a Center of Academic Excellence in Cyber Defense (CAE-CD) by the National Security Agency (NSA).",
      descBullets: [
        "follows National Security Telecommunications and Systems Security Instruction (NSTISSI) 4011 and 4013 standards.",
        "certified as mapping 100% to the Committee on National Security Systems (CNSS) National Standard 4011"
      ]
    },
      {
      schoolName: "Server Academy",
      logo: require("./assets/images/server.png"),
      subHeader: "Certified Deployment Associate",
      duration: "September 1975 - April 1978",
      desc: "Designed CCu to build the skills needed to embrace the challenges of any job",
      descBullets: [
	 "Offered curriculum in cloud, and development technologies"]
    }
  ]
};

// Your top 3 proficient stacks/tech experience

const techStack = {
  viewSkillBars: false, //Set it to true to show Proficiency Section
  experience: [
    {
      Stack: "Frontend/Design", //Insert stack or technology you have experience in
      progressPercentage: "90%" //Insert relative proficiency in percentage
    },
    {
      Stack: "Backend",
      progressPercentage: "70%"
    },
    {
      Stack: "Programming",
      progressPercentage: "60%"
    }
  ],
  displayCodersrank: false // Set true to display codersrank badges section need to changes your username in src/containers/skillProgress/skillProgress.js:17:62, defaults to false
};

// Work experience section

const workExperiences = {
  display: false, //Set it to true to show workExperiences Section
  experience: [
    {
      role: "System Ingeneer",
      company: "Vontaas",
      companylogo: require("./assets/images/vontas.png"),
      date: " April 2021 - Present",
      desc: "My most recent position",
      descBullets: [
        "Managed the deployment, monitoring, maintenance, development, upgrade, and support of all IT systems, including servers and workstations.",
        "Implemented fully automated and unattended deployment of Windows hosts with PowerShell scripts, WDS and MDT",
	"Implemented backup and disaster recovery solutions to protect data integrity and minimize downtime. Tested backup systems regularly to ensure reliability with Veeam and Acronis.",
	"Implemented security measures to protect systems from unauthorized access, viruses, and other cyber threats.",
	"Increased efficiency by automating routine administrative tasks using PowerShell and Bash",
	"Provided Windows server management and administration of Active Directory including maintaining file servers, group policy, replication, print servers, DNS, DHCP, managing OUs, Applying GPOs to sites.",
	"Maintained accurate documentation of system configurations, procedures, and troubleshooting steps for improved knowledge transfer and enhanced troubleshooting efficiency."
      ]
    },
    {
      role: "Volunteer",
      company: "Project Reboot",
      companylogo: require("./assets/images/reboot.jpg"),
      date: "February 2017 – May 2019",
      desc: "Volunteered to fix and provide computers to low-income residents",
      descBullets: [
	  "Assembled and disassembled Desktops and Laptops (HP, Dell, Lenovo, Acer, …) for refurbishment purpose",
	  "Suggested and Implemented MDT to automate Windows 10 deployment, reducing workload, and received praises from management",
	  "Deployed Windows 10 on various computers through Microsoft Deployment Toolkit (MDT",
	  "Performed various software and patches installations"
      ]
    },
    {
      role: "Projects",
      company: "Github",
      companylogo: require("./assets/images/git.jpg"),
      date: "Present",
      desc: "All my personnal Projects are hosted on my github repository and youtube channel."
    }
  ]
};

/* Your Open Source Section to View Your Github Pinned Projects
To know how to get github key look at readme.md */

const openSource = {
  showGithubProfile: "true", // Set true or false to show Contact profile using Github, defaults to true
  display: true // Set false to hide this section, defaults to true
};

// Some big projects you have worked on

const bigProjects = {
  title: "My Projects",
  subtitle: "A FEW PROJECTS I HAVE WORKED ON",
  projects: [
    {
      image: require("./assets/images/bottom_Diag.png"),
      projectName: "Network Design, Implementation and Security",
      projectDesc: "IT networks are the backbone of today’s communication infrastructures. As they become mission-critical for business functions, design decisions made by IT professionals can have far-reaching implications. A network with a well-planned design will perform better. It will be more secure and resilient and easier to troubleshoot, and it will scale easily and adapt to future technologies. This project demonstrates my expertise in crafting and implementing such solutions.",
      footerLink: [
        {
          name: "See Project",
          url: "https://github.com/8Ten10/Network-Design-Implementation-and-Security"
	},
        {
          name: "Video demonstration",
          url: "https://www.youtube.com/watch?v=1HC-K3ZQtRo&list=PLcsz_xs5B86QdHUge6v2alwKYctA-qpro"
        }
      ]
    },
    {
      image: require("./assets/images/an.png"),
      projectName: "Deploying A containerized react App kevintsafack.io with GitHub Actions",
      projectDesc: "This project is here to demonstrate a CI/CD pipeline used to deploy a React application ( kevintsafack.io) using GitHub Actions.",
      footerLink: [
        {
          name: "See Project",
          url: "https://github.com/8Ten10/Deploying-kevintsafack.live-with-GitHub-Actions"
        }
      ]
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Achievement Section
// Include certificates, talks etc

const achievementSection = {
  title: emoji("Achievements And Certifications 🏆 "),
  subtitle:
    "Achievements, Certifications Obtained in Recent months 🙂",

  achievementsCards: [
    {
      title: "Red Hat Certified Engineer",
      subtitle:
        "The performance-based Red Hat Certified Engineer (RHCE) exam (EX294) tests your knowledge and skill in managing multiple systems using Red Hat Ansible Engine and executing common system administration tasks across a number of systems with Ansible.",
      image: require("./assets/images/rhce.png"),
     // imageAlt: "Google Code-In Logo",
      footerLink: [
        {
          name: "Certification",
          url: "https://rhtapps.redhat.com/verify?certId=230-052-730"
        },
        {
          name: "Award Letter",
          url: "https://drive.google.com/file/d/1vx1RKUFFwnB4D3Ax7cKzJ0uV84iPYYNj/view?usp=drive_link"
        },
      ]
    },
    {
      title: "Red Hat Certified System Administrator",
      subtitle:
        "The performance-based Red Hat Certified System Administrator (RHCSA) exam (EX200) tests your knowledge in areas of system administration common across a wide range of environments and deployment scenarios.",
      image: require("./assets/images/rhcsa.png"),
      //imageAlt: "Google Assistant Action Logo",
      footerLink: [
        {
          name: "Certification",
          url: "https://rhtapps.redhat.com/verify?certId=230-052-730"
        },
        {
          name: "Award Letter",
          url: "https://drive.google.com/file/d/17BYyK_iNlpJQHrDA-_Tn_ULtIz-L3R1p/view?usp=sharing"
        },
      ]
    },

    {
      title: "CompTIA Security+ ce ",
      subtitle: "Earners of the CompTIA Security+ certification have the knowledge and skills necessary to perform core security functions required of any cybersecurity role. CompTIA Security+ professionals know how to identify and address potential threats, attacks and vulnerabilities and they have established techniques in risk management, risk mitigation, threat management and intrusion detection.",
      image: require("./assets/images/security.png"),
      //imageAlt: "PWA Logo",
      footerLink: [
        {name: "Certification", url: "https://www.credly.com/badges/e39d7589-e7f1-42ab-b2f0-57326f3cee26/public_url"},
        {
          name: "Award Letter",
          url: "https://drive.google.com/file/d/1kfKY44iR0Upv81o-kRJPSpMTR-jl_pCv/view?usp=sharing"
        }
      ]
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Blogs Section

const blogSection = {
  title: "Blogs",
  subtitle:
    " I love to write and teach others what I have learnt.",
  displayMediumBlogs: "true", // Set true to display fetched medium blogs instead of hardcoded ones
  blogs: [
    {
      url: "https://github.com/8Ten10/HUANANZHI-X99-8M-F-Hackintosh",
      title: "Hacking a Huananzhi X99-8M-F MoBo",
      description:
        "A relatively simple guide on how to hack a MoBo to get SOnoma to work with it. My EFI is shared here with a few other tools used to get things done."
    },
    {
      url: "https://www.youtube.com/watch?v=1HC-K3ZQtRo&list=PLcsz_xs5B86QdHUge6v2alwKYctA-qpro",
      title: "Building a Network Infrastructure",
      description:
        "Here I have a playlist containing a few videos showcasing a complete network deployment with both frontend and backend infrastructures in a nested VM. "
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Talks Sections

const talkSection = {
  title: "Youtube Channel 🎥 🎙️",
  subtitle: emoji(
    "I LOVE TO SHARE MY LIMITED KNOWLEDGE AND GET A SPEAKER BADGE 😅"
  ),

  talks: [
    {
      title: "All The Cool Stuff on my Youtube Channel ",
      //subtitle: "",
      slides_url: "https://www.youtube.com/watch?v=1HC-K3ZQtRo&list=PLcsz_xs5B86QdHUge6v2alwKYctA-qpro",
      event_url: "https://www.youtube.com/watch?v=1HC-K3ZQtRo&list=PLcsz_xs5B86QdHUge6v2alwKYctA-qpro"
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Podcast Section

const podcastSection = {
  title: emoji("Youtube Channel 🎙️"),
  subtitle: "I LOVE TO TALK ABOUT TECHNOLOGY",

  // Please Provide with Your Podcast embeded Link
  podcast: [
    "NaNaNa"
  ],
  display: false // Set false to hide this section, defaults to true
};

const contactInfo = {
  title: emoji("Contact Me ☎️ "),
  subtitle:"Discuss a project or just want to say hi? My Inbox is open for all",
  number: "(202) 825 4521",
  email_address: "bonjour@kevintsafack.io"
};

// Twitter Section

const twitterDetails = {
  userName: "twitter", //Replace "twitter" with your twitter username without @
  display: false // Set true to display this section, defaults to false
};

const isHireable = true; // Set false if you are not looking for a job. Also isHireable will be display as Open for opportunities: Yes/No in the GitHub footer

export {
  illustration,
  greeting,
  socialMediaLinks,
  splashScreen,
  skillsSection,
  educationInfo,
  techStack,
  workExperiences,
  openSource,
  bigProjects,
  achievementSection,
  blogSection,
  talkSection,
  podcastSection,
  contactInfo,
  twitterDetails,
  isHireable
};
